import React, {ReactElement, ReactNode, useEffect} from "react";
import ApiContext from "@/hoc/Api/context";
import UserInfo from "@/models/UserInfo";
import axios from "axios";
import MembershipCard from "@/models/MembershipCard";
import Preorder from "@/models/Preorder";
import UserTickets from "@/models/UserTickets";
import Event from "@/models/Event";
import EventIssuer from "@/models/EventIssuer";
import ReductionCode from "@/models/ReductionCode";
import Section from "@/models/Section";
import MembershipCardCreationData from "@/models/MembershipCardCreationData";
import SellingTickets from "@/models/SellingTickets";
import UserTicket from "@/models/UserTicket";
import Ticketorder from "@/models/Ticketorder";
import PayoutProfile from "@/models/PayoutProfile";
import BalanceLog from "@/models/BalanceLog";
import {LoggedUser} from "@/models/AuthUser";
import MembershipCardValidation from "@/models/MembershipCardValidation";
import {useNavigate} from "react-router-dom";

interface ApiProviderProps {
    children: ReactNode;
    baseUrl: string;
}

const AuthTokenKey = "authToken"

const ApiProvider = (props: ApiProviderProps): ReactElement => {

    const headers = (): any => {
        return {Authorization: `Bearer ${localStorage.getItem(AuthTokenKey)}`, Accept: "application/json"}
    }

    const login = (email: string, password: string): Promise<boolean> => {
        return axios.post(`${props.baseUrl}/auth/login`, {
            email: email,
            password: password
        }, {headers: headers()}).then(resp => {
            if (resp.data.token) {
                localStorage.setItem(AuthTokenKey, resp.data.token)
                return true;
            }
            return false;
        }).catch(() => {
            return false;
        })
    }

    const register = (email: string, password: string, name: string, privacyConsent: boolean, termsConsent: boolean, marketingConsent: boolean): Promise<UserInfo> => {
        return axios.post(`${props.baseUrl}/auth/register`, {
            email: email,
            password: password,
            name: name,
            consents: {
                terms: termsConsent,
                privacy: privacyConsent,
                marketing: marketingConsent
            }
        }, {headers: headers()}).then(r => r.data)
    }

    const isLogged = (): Promise<LoggedUser> => {
        return new Promise<LoggedUser>((resolve, reject) => {
            const authToken = localStorage.getItem(AuthTokenKey)
            if (authToken === "" || authToken === null || authToken === undefined) {
                resolve({
                    isLogged: false,
                    authUser: undefined,
                })
                return
            }
            axios.get(`${props.baseUrl}/auth/verifyToken`, {headers: headers()}).then(resp => {
                if (resp.status === 401) {
                    localStorage.removeItem(AuthTokenKey)
                    resolve({
                        isLogged: false,
                        authUser: undefined,
                    })
                } else if (resp.status === 200) {
                    resolve({
                            isLogged: true,
                            authUser: {
                                email: resp.data.email,
                                role: resp.data.role
                            }
                        }
                    )
                }
            }).catch(err => {
                localStorage.removeItem(AuthTokenKey)
                resolve({
                    isLogged: false,
                    authUser: undefined,
                })
            })
        })
    }

    const userInfo = (): Promise<UserInfo> => {
        return axios.get(`${props.baseUrl}/user`, {headers: headers()}).then(resp => {
            return resp.data
        })
    }

    const getUserMembershipCards = (): Promise<MembershipCard[]> => {
        return axios.get(`${props.baseUrl}/membershipCards`, {headers: headers()}).then(resp => {
            return resp.data
        })
    }

    const getPreorder = (): Promise<Preorder> => {
        return axios.get(`${props.baseUrl}/orders/preorder`, {headers: headers()}).then(resp => {
            return resp.data;
        })
    }

    const getMembershipCard = (uuid: string): Promise<MembershipCard> => {
        return axios.get(`${props.baseUrl}/membershipCards/${uuid}`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const deleteMembershipCard = (uuid: string): Promise<boolean> => {
        return axios.delete(`${props.baseUrl}/membershipCards/${uuid}`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const getUserTickets = (): Promise<UserTickets> => {
        return axios.get(`${props.baseUrl}/tickets`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const getActiveEvents = (): Promise<Event[]> => {
        return axios.get(`${props.baseUrl}/events`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const getActiveEventIssuers = (): Promise<EventIssuer[]> => {
        return axios.get(`${props.baseUrl}/eventIssuers`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const getEventIssuer = (uuid: string): Promise<EventIssuer> => {
        return axios.get(`${props.baseUrl}/eventIssuers/${uuid}`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const getEvent = (id: string): Promise<Event> => {
        return axios.get(`${props.baseUrl}/events/${id}`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const getEventIssuerReductionCodes = (id: string): Promise<ReductionCode[]> => {
        return axios.get(`${props.baseUrl}/eventIssuers/${id}/reductionCodes`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const getEventLocationSections = (id: string): Promise<Section[]> => {
        return axios.get(`${props.baseUrl}/eventLocations/${id}/sections`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const createMembershipCard = (data: MembershipCardCreationData): Promise<MembershipCard> => {
        return axios.post(`${props.baseUrl}/membershipCards`, data, {
            headers: headers(),
            validateStatus: (status: number) => status === 200
        }).then(r => {
            console.log("resp", r);
            return r.data
        }).catch(e => {
            console.log("ERROR", e);
            throw e.response.data.code;
        })
    }

    const getEventSellingTickets = (uuid: string): Promise<SellingTickets> => {
        return axios.get(`${props.baseUrl}/events/${uuid}/tickets`, {headers: headers()}).then(
            r => r.data
        )
    }

    const sellTicket = (uuid: string): Promise<UserTicket> => {
        return axios.put(`${props.baseUrl}/tickets/${uuid}/sell`, {}, {headers: headers()}).then(r => r.data)
    }

    const unsellTicket = (uuid: string): Promise<UserTicket> => {
        return axios.put(`${props.baseUrl}/tickets/${uuid}/unsell`, {}, {headers: headers()}).then(r => r.data)
    }

    const addTicketToPreorder = (uuid: string): Promise<Preorder> => {
        return axios.put(`${props.baseUrl}/tickets/${uuid}/addToPreorder`, {}, {headers: headers()}).then(r => r.data)
    }

    const addTicketClusterToPreorder = (clusterUuid: string): Promise<Preorder> => {
        return axios.put(`${props.baseUrl}/tickets/cluster/${clusterUuid}/addToPreorder`, {}, {headers: headers()}).then(r => r.data)
    }

    const removeTicketToPreorder = (uuid: string): Promise<boolean> => {
        return axios.delete(`${props.baseUrl}/orders/preorder/ticketOrders/${uuid}`, {headers: headers()}).then(r => r.data)
    }

    const associateMembershipCard = (ticketOrderUuid: string, membershipCardUuid: string): Promise<Ticketorder> => {
        return axios.put(`${props.baseUrl}/orders/preorder/ticketOrders/${ticketOrderUuid}/associate`, {membership_card_uuid: membershipCardUuid}, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const disassociateMembershipCard = (ticketOrderUuid: string): Promise<Ticketorder> => {
        return axios.put(`${props.baseUrl}/orders/preorder/ticketOrders/${ticketOrderUuid}/disassociate`, {}, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const finalizePreorder = (): Promise<Preorder> => {
        return axios.put(`${props.baseUrl}/orders/preorder/finalize`, {}, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const getOrder = (id: string): Promise<Preorder> => {
        return axios.get(`${props.baseUrl}/orders/${id}`, {headers: headers()}).then(r => {
            return r.data
        })
    }

    const confirmUser = (token: string): Promise<UserInfo> => {
        return axios.post(`${props.baseUrl}/auth/verifyEmail?token=${token}`, {}).then(u => {
            return u.data
        })
    }

    const changePassword = (oldPassword: string, newPassword: string): Promise<UserInfo> => {
        return axios.post(`${props.baseUrl}/user/changePassword`, {
            oldPassword: oldPassword,
            newPassword: newPassword
        }, {headers: headers()}).then(u => {
            return u.data
        })
    }

    const recoveryPassword = (token: string, newPassword: string): Promise<UserInfo> => {
        return axios.post(`${props.baseUrl}/auth/changePassword?token=${token}`, {
            password: newPassword
        }).then(u => {
            return u.data
        })
    }

    const requestRecoveryPassword = (email: string): Promise<UserInfo> => {
        return axios.post(`${props.baseUrl}/auth/changePasswordRequest`, {
            email: email
        }).then(u => {
            return u.data
        })
    }

    const uploadProfileImage = (formData: FormData): Promise<UserInfo> => {
        return axios.post(`${props.baseUrl}/user/profileImage`, formData, {headers: headers()}).then(
            r => r.data
        )
    }

    const getBase64Media = (id: string): Promise<string> => {
        return axios.get(`${props.baseUrl}/media/${id}/base64`, {headers: headers()}).then(resp => {
            return `data:${resp.data.contentType
            };base64,${resp.data.content}`;
        })
    }

    const getRawMedia = (id: string): Promise<void> => {
        return axios({
            url: `${props.baseUrl}/media/${id}`,
            method: 'GET',
            responseType: 'blob',
            headers: headers(),
        }).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            //URL.revokeObjectURL(url);
        });
    }

    const getMediaUrl = (id: string): Promise<string> => {
        return axios.get(`${props.baseUrl}/media/${id}/url`, {headers: headers()}).then(resp => {
            return resp.data.url;
        })
    }

    const logout = (fromAll: boolean): Promise<void> => {
        return axios.post(`${props.baseUrl}/user/logout${fromAll ? "?disconnect=true" : ""}`, {}, {headers: headers()}).then(r => {
            if (r.data.ok) {
                localStorage.removeItem(AuthTokenKey)
            } else {
                throw new Error("error loging out")
            }
        })
    }

    const uploadKycDocs = (formData: FormData, additional: boolean): Promise<PayoutProfile> => {
        return axios.post(`${props.baseUrl}/user/payoutProfile/kyc${additional ? "?additional=true" : ""}`, formData, {headers: headers()}).then(
            r => r.data
        )
    }

    const updatePayoutProfile = (payoutProfile: PayoutProfile): Promise<PayoutProfile> => {
        return axios.post(`${props.baseUrl}/user/payoutProfile`, payoutProfile, {headers: headers()}).then(
            r => r.data
        )
    }

    const getUserTransactions = (): Promise<BalanceLog[]> => {
        return axios.get(`${props.baseUrl}/user/transactions`, {headers: headers()}).then(r => r.data)
    }

    const requirePayout = (): Promise<void> => {
        return axios.post(`${props.baseUrl}/user/payout`, {}, {headers: headers()})
    }

    const confirmPartialOrder = (uuid: string): Promise<Preorder> => {
        return axios.put(`${props.baseUrl}/orders/${uuid}/complete`, {}, {headers: headers()}).then(r => r.data)
    }

    const cancelPartialOrder = (uuid: string): Promise<Preorder> => {
        return axios.put(`${props.baseUrl}/orders/${uuid}/cancel`, {}, {headers: headers()}).then(r => r.data)
    }

    const deleteUser = (password: string): Promise<void> => {
        return axios.put(`${props.baseUrl}/user/delete`, {password}, {headers: headers()}).then(r => {
            localStorage.removeItem(AuthTokenKey)
        })
    }

    const getUserValidation = (): Promise<MembershipCardValidation[]> => {
        return axios.get(`${props.baseUrl}/validation`, {headers: headers()}).then(r => r.data)
    }
    const postUserValidation = (eventIssuerUuid: string): Promise<any> => {
        return axios.post(`${props.baseUrl}/validation`, {eventIssuerUuid}, {headers: headers()}).then(r => r.data)
    }
    const getSingleUserValidation = (id: string): Promise<MembershipCardValidation> => {
        return axios.get(`${props.baseUrl}/validation/${id}`, {headers: headers()}).then(r => r.data)
    }
    const navigate = useNavigate();

    useEffect(() => {
        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response.status === 401) {
                localStorage.removeItem(AuthTokenKey);
                navigate("/Auth");
            }
        });
    }, []);
    return <ApiContext.Provider value={{
        Login: login,
        Logout: logout,
        GetMediaUrl: getMediaUrl,
        CreateMembershipCard: createMembershipCard,
        DeleteMembershipCard: deleteMembershipCard,
        IsLogged: isLogged,
        UserInfo: userInfo,
        GetMembershipCards: getUserMembershipCards,
        GetPreorder: getPreorder,
        GetMembershipCard: getMembershipCard,
        GetUserTickets: getUserTickets,
        GetActiveEvents: getActiveEvents,
        GetActiveEventIssuers: getActiveEventIssuers,
        GetEventIssuer: getEventIssuer,
        GetEvent: getEvent,
        GetEventIssuerReductionCodes: getEventIssuerReductionCodes,
        GetEventLocationSections: getEventLocationSections,
        GetEventSellingTickets: getEventSellingTickets,
        SellTicket: sellTicket,
        UnsellTicket: unsellTicket,
        AddTicketToPreorder: addTicketToPreorder,
        RemoveTicketToPreorder: removeTicketToPreorder,
        AssociateMembershipCard: associateMembershipCard,
        FinalizePreorder: finalizePreorder,
        GetOrder: getOrder,
        Register: register,
        ConfirmUser: confirmUser,
        ChangePassword: changePassword,
        RecoveryPassword: recoveryPassword,
        RecoveryPasswordRequest: requestRecoveryPassword,
        UploadProfileImage: uploadProfileImage,
        GetBase64Media: getBase64Media,
        DisassociateMembershipCard: disassociateMembershipCard,
        UploadKYCDocs: uploadKycDocs,
        UpdatePayoutProfile: updatePayoutProfile,
        AddTicketClusterToPreorder: addTicketClusterToPreorder,
        GetUserTransactions: getUserTransactions,
        RequirePayout: requirePayout,
        GetRawMedia: getRawMedia,
        CancelPartialOrder: cancelPartialOrder,
        ConfirmPartialOrder: confirmPartialOrder,
        DeleteUser: deleteUser,
        GetValidations: getUserValidation,
        PostValidation: postUserValidation,
        GetSingleValidation: getSingleUserValidation
    }}>
        {props.children}
    </ApiContext.Provider>
}

export default ApiProvider
